import MultiChannelGuideline from 'components/MultiChannelGuideline';
import React from 'react'

const ConsentGuideline = () => {
  return (
    <div className="col-sm-12">
        <div className="cw-section cw-dashboard ml-20">
      <MultiChannelGuideline isConsentPage={true}/>
    </div>
  </div>
  )
}

export default ConsentGuideline;
