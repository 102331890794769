import React from 'react';
import parse from 'html-react-parser';
import { get } from 'lodash';


export function getTabularData(consentsData, country, langauge) {
  const generalConsents = [],
    seperateChannelConsents = [],
    combinedMultiChannelConsents = [], defaultText = 'Not available in central records';
    const fieldPath = "Version 3 - Enterprise Consents.0"
    const getData = (path) => {
        const dataItem = get(consentsData, path);
        if(dataItem){
            return parse(dataItem);
        }
        return defaultText;
    }
    const isUSEpsilon = country === 'US';
    const isItaly = country === 'IT';
    // General consents
    generalConsents.push({
        label: "Age consent",
        value: getData(`${fieldPath}.age_consent`, defaultText)
    });

    generalConsents.push({
        label: "Privacy Policy",
        value: getData(`${fieldPath}.privacy_policy`, defaultText)
    });

    // Seperate channel consents
    if(isItaly) {
        seperateChannelConsents.push({
            label: "Unilever Email only consent",
            value: getData(`${fieldPath}.email_only`, defaultText)
        });
    
        seperateChannelConsents.push({
            label:"Unilever SMS only consent",
            value: getData(`${fieldPath}.sms_only`, defaultText)
        });
    
        seperateChannelConsents.push({
            label:"Unilever WhatsApp only consent",
            value: getData(`${fieldPath}.unilever_whatsapp_only`, defaultText)
        });

        seperateChannelConsents.push({
            label:"Unilever online advertising consent",
            value: getData(`${fieldPath}.online_only`, defaultText)
        });

    } else {
        seperateChannelConsents.push({
            label: isUSEpsilon? "Combined Unilever and Brand email consent":"Unilever Email consent",
            value: getData(`${fieldPath}.unilever_email`, defaultText)
        });
    
        seperateChannelConsents.push({
            label:"Unilever SMS consent",
            value: getData(`${fieldPath}.unilever_sms`, defaultText)
        });
    
        seperateChannelConsents.push({
            label:"Unilever WhatsApp consent",
            value: getData(`${fieldPath}.unilever_whatsapp`, defaultText)
        });
    }
    
    

    // Combined multi channel consents
    combinedMultiChannelConsents.push({
        label:"Unilever Email, SMS and WhatsApp consents",
        value: getData(`${fieldPath}.multi_channel_email_sms_whatsapp`, defaultText),
        headerToolTip: "To be used on all sign up forms that collect consumer email address and mobile number in non-GDPR countries like India"
    });
    combinedMultiChannelConsents.push({
        label:"Unilever SMS and WhatsApp consent ",
        value: getData(`${fieldPath}.multi_channel_sms_whatsapp`, defaultText),
        headerToolTip: "To be used on all sign up forms that collect only consumer mobile number in non-GDPR countries like India"
    });
    combinedMultiChannelConsents.push({
        label:"Unilever Email and SMS consent",
        value: getData(`${fieldPath}.multi_channel_email_sms`, defaultText),
        headerToolTip: "To be used on all sign up forms that collect consumer email address and mobile number in non-GDPR countries where WhatsApp consent is not enabled"
    });



  return {generalConsents, seperateChannelConsents, combinedMultiChannelConsents};
}
