import React from 'react';
import ReactTooltip from 'react-tooltip';

import { maskData } from 'selectors';

import constants from 'constants/config';
import moment from 'moment';

export const CouponCodeDisplay = ({
  emailAttributes,
  userPermitted,
  couponFileTrack
}) => {
  const couponType = emailAttributes?.emailCoupon?.type;

  if (couponType === 'staticCoupon') {
    return (
      <>
        {userPermitted
          ? emailAttributes?.emailCoupon?.code
          : maskData(emailAttributes?.emailCoupon?.code)}
        <span
          className="icon"
          style={{ cursor: 'pointer' }}
          data-tip="The coupon code is visible only to campaign owner, collaborators, approvers, ACS execution team and admins. Anyone else will see the coupon code as masked"
          data-for="staticCoupon"
        >
          &nbsp;<i className="fas fa-question-circle"></i>
        </span>
        <ReactTooltip
          id="staticCoupon"
          place="right"
          type="info"
          multiline={true}
          className="cw-tooltip cw-email-tooltip"
        />
      </>
    );
  } else if (couponType === 'dynamicCoupon') {
    if (emailAttributes?.emailCoupon?.couponFileUpload === 'success') {
      const couponFileName = couponFileTrack?.couponFileName;
      const couponFileUploadTime = couponFileTrack?.couponFileUploadTime;
      return `Coupon codes sent to ACS on ${moment(couponFileUploadTime).format(
        'DD MMM YYYY'
      )} in file '${couponFileName}'`;
    }
    if (emailAttributes?.emailCoupon?.couponFileUpload === 'failed') {
      if (emailAttributes?.emailCoupon?.shareCouponFileViaKana) {
        return emailAttributes?.emailCoupon?.kanaTicketNumber
        ? `Coupon upload from CW to ACS failed. Coupons have instead been uploaded via UNA ticket ${emailAttributes.emailCoupon.kanaTicketNumber}`
        : 'Awaiting UNA ticket number from requestor';
      }
    }
    return 'Awaiting coupon codes file upload from requestor';
  }

  return emailAttributes?.emailCoupon?.kanaTicketNumber
    ? `Dynamic coupons uploaded via UNA ticket ${emailAttributes.emailCoupon.kanaTicketNumber}`
    : 'Awaiting UNA ticket number from requestor';
};

export const couponDisplay = (couponApplied, couponType) =>
  couponApplied
    ? couponType === 'dynamicCoupon'
      ? 'Email includes dynamic coupons'
      : `Includes ${constants.EMAIL_COUPON_TYPE[couponType]} in email`
    : 'No coupons in email';
