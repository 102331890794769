import {
  fetchConsentData,
  fetchCountryList,
  fetchLanguageList
} from 'actions/dashboard';
import classNames from 'classnames';
import { BreadCrumbItem } from 'components/BreadCrumb';
import CheckboxDropDown from 'components/CheckboxDropDown';
import Loader from 'components/Loader';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '../../images/times.png';
import DownloadIcon from '../../images/download-image.svg';
import axios from 'axios';
import fileDownload from 'js-file-download';
import constants from 'constants/config';
import previewImg from '../../images/dashboard-list-preview.svg';
import ReactTooltip from 'react-tooltip';

const statusList = [
  { value: 'Not available', label: 'Not available' },
  { value: 'Fully/partially available', label: 'Fully/partially available' }
];

const breadcrumbdata = [
  {
    path: '/campaign-wizard',
    title: 'Home'
  },
  {
    path: '/campaign-wizard/consent-statement',
    title: 'Consent statements'
  }
];

const ConsentComponent = (props) => {
  const dispatch = useDispatch();
  const [language, setLanguage] = useState([]);
  const [country, setCountry] = useState([]);
  const [status, setStatus] = useState([]);
  const [filter, setFilter] = useState([]);
  const [sortBy, setSortBy] = useState("asc");
  const [sortKey, setSortKey] = useState("country");

  const { data, countries, languages, isLoading, error } = useSelector(
    state => {
      return {
        data:
          state.dashboard &&
          state.dashboard.consent &&
          state.dashboard.consent.data &&
          state.dashboard.consent.data.data,
        isLoading:
          state.dashboard &&
          state.dashboard.consent &&
          state.dashboard.consent.loading,
        error:
          state.dashboard &&
          state.dashboard.consent &&
          state.dashboard.consent.error,
        countries: state.dashboard && state.dashboard.countryList,
        languages: state.dashboard && state.dashboard.languageList
      };
    }
  );

  useEffect(() => {
    dispatch(fetchConsentData());
    dispatch(fetchCountryList());
    dispatch(fetchLanguageList());
  }, [dispatch]);

  //if (!data) return null;
  const getCountries = () => {
    if (countries && countries.length > 0) {
      return countries.map(function(country) {
        return { value: country._id, label: country.name };
      });
    }
  };

  const getLanguage = () => {
    if (languages && languages.length > 0) {
      return languages.map(function(lang) {
        return { value: lang._id, label: lang.name };
      });
    }
  };

  const handleCountrySelect = selectedOption => {
    setCountry(selectedOption);
  };

  const handleEnglishSelect = selectedOption => {
    setLanguage(selectedOption);
  };

  const handleStatusSelect = selectedOption => {
    setStatus(selectedOption);
  };

  const clearAllFilters = () => {
    setCountry([]);
    setLanguage([]);
    setStatus([]);
  };

  const removeFilter = value => {
    let countryIds = country;
    if(country && country.find(e=> e.value === value)){
      countryIds = country && country.length > 0
      ? country.filter(e => e.value != value)
      : [];
    }

    let languageIds  = language;
    if(language && language.find(e=> e.value === value)){
      languageIds = language && language.length > 0
        ? language.filter(e => e.value != value)
        : [];
    }
     
    let statusIds  = status;
    if(status && status.find(e=> (e&& e.value) === value)){
      statusIds =
      status && status.length > 0 ? status.filter(e => e.value != value) : [];
    }
   
    setFilter([...countryIds, ...languageIds, ...statusIds]);
    setCountry(countryIds);
    setLanguage(languageIds);
    setStatus(statusIds);
  };

  useEffect(() => {
    let countryIds =
      country && country.length > 0 ? country.map(e => e.value) : [];
    let languageIds =
      language && language.length > 0 ? language.map(e => e.value) : [];
    let statusValues =
      status && status.length > 0 ? status.map(e => e.value) : [];

    setFilter([...country, ...language, ...status]);
    dispatch(fetchConsentData({ countryIds, languageIds, statusValues ,sortBy,sortKey}));
  }, [country, language, status]);

  const applySortFilter = (sortBy, sortKey) => {
    let countryIds =
      country && country.length > 0 ? country.map(e => e.value) : [];
    let languageIds =
      language && language.length > 0 ? language.map(e => e.value) : [];
    let statusValues =
      status && status.length > 0 ? status.map(e => e.value) : [];



    setFilter([...country, ...language, ...status]);
    dispatch(
      fetchConsentData({
        countryIds,
        languageIds,
        statusValues,
        sortBy,
        sortKey
      })
    );
  };


  
  const sortClick = (e) =>{
    let classname = e.target.className;
    let updatedClassName;
    let sortBy = ''
    document.querySelectorAll('.current-sorted').forEach(el => el.classList.remove('current-sorted'))
    if (classname.indexOf('--asc') >= 0) {
      sortBy = 'asc';
      updatedClassName = 'cell cw-sort cw-sort--desc current-sorted';
    } else {
      sortBy = 'desc';
      updatedClassName = 'cell cw-sort cw-sort--asc current-sorted';
    }
    let sortKey = e.currentTarget.getAttribute('data-type');
    e.target.className = updatedClassName;
    setSortBy(sortBy)
    setSortKey(sortKey)
    applySortFilter(sortBy, sortKey)
  }

  const downloadFile = url => {
    axios({ method: 'get', url: url, responseType: 'blob' })
      .then(response => {
        let fileName =
          response.headers['content-disposition']?.split('filename=')[1] ||
          `file_${new Date().getTime()}`;
        fileDownload(response.data, fileName);
      })
      .catch(err => {
        console.log('download report error: ', err);
      });
  };
  
  const handleDownloadConsent = ()=>{
    downloadFile(constants.serviceUrls.EXPORT_CONSENT_LIST)
  }

  return (
    <>
      <div className="col-sm-12">
        <div className="cw-section cw-dashboard ml-20">
          {breadcrumbdata && breadcrumbdata.length && (
            <BreadCrumbItem item={breadcrumbdata} />
          )}
          <div className="cw-listing--header mb-20 d-flex align-items-center justify-content-between">
            <h2 className="cw-heading--secondary mb-20 mr-4 d-inline-block">
              Consent statements dashboard
            </h2>
            <ReactTooltip
              id={`download-icon`}
              place="bottom"
              type="info"
              multiline={true}
              className="cw-tooltip cw-email-tooltip saas_new_master_tooltip"
            />
            <button
              onClick={handleDownloadConsent}
              className={'action-bar__action float-right mr-30'}
              data-tip={`Export consent statements`}
              data-for={`download-icon`}
            >
              <img src={DownloadIcon} alt={'no image'} />
            </button>
          </div>
        </div>
        <div className="cw-listing--content px-5">
          <div className="row mt-20 cw-dashboard__filters">
            <div className="d-flex align-items-center col-sm-8 mb-10">
              {/* Country dropdown */}
              <div className="filter-item mr-10">
                <div className="form-group mb-0">
                  <CheckboxDropDown
                    isMulti={true}
                    className={'cw-dashboard-filter'}
                    menuIsOpen={false}
                    name={'brandcountry'}
                    label={'Country'}
                    hideSelectedOptions={false}
                    placeholder={'Country'}
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    value={country}
                    onChange={handleCountrySelect}
                    options={getCountries()}
                    isClearable={false}
                  />
                </div>
              </div>

              {/* Language Filter */}
              <div className="filter-item mr-10">
                <div className="form-group mb-0">
                  <CheckboxDropDown
                    isMulti={true}
                    className={'cw-dashboard-filter'}
                    menuIsOpen={true}
                    name={'language'}
                    label={'Language'}
                    hideSelectedOptions={false}
                    placeholder={'Language'}
                    value={language}
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    onChange={handleEnglishSelect}
                    options={getLanguage()}
                    isClearable={false}
                  />
                </div>
              </div>
              {/* stautes Filter */}
              <div className="filter-item mr-10">
                <div className="form-group mb-0">
                  <CheckboxDropDown
                    isMulti={true}
                    className={'cw-dashboard-filter status-width'}
                    menuIsOpen={true}
                    name={'Status'}
                    label={'Marketing consent status'}
                    hideSelectedOptions={false}
                    placeholder={'Marketing consent status'}
                    value={status}
                    isDisabled={isLoading}
                    // isLoading={isLoading}
                    onChange={handleStatusSelect}
                    options={statusList}
                    isClearable={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-30">
            <div>
              {filter && filter.length > 0 ? (
                <>
                  {filter.map(e => {
                    return (
                      <div className="cw-dashboard-filters">
                        <div className="cw-dashboard-filters-label">
                          {e.label}
                        </div>
                        <div
                          className="cw-dashboard-filters-remove"
                          onClick={() => removeFilter(e.value)}
                        >
                          <img src={DeleteIcon} alt="Remove filter icon" />
                        </div>
                      </div>
                    );
                  })}

                  {filter && filter.length >= 2 ? (
                    <div className="cw-dashboard-filters clearall">
                      <div
                        className="cw-dashboard-filters-label clearalllabel"
                        onClick={clearAllFilters}
                      >
                        Clear all
                      </div>
                    </div>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>

          <table className="table table-striped-dashboard mb-40">
            <caption>List of campaigns</caption>
            <thead>
              <tr>
                <th scope="col">
                  <span
                    data-type="country"
                    className="cell cw-sort cw-sort--desc"
                    onClick={sortClick}
                  >
                    Country
                  </span>
                </th>
                <th scope="col" data-type="name">
                  <span>Language</span>
                </th>
                <th scope="col">
                  <span>Last updated</span>
                </th>
                <th scope="col">
                  <span
                    data-type="status"
                    className="cell cw-sort cw-sort--desc"
                    onClick={sortClick}
                  >
                    Status
                  </span>
                </th>
                <th scope="col">
                  <span>View consents </span>
                </th>
              </tr>
            </thead>
            {isLoading ? (
              <Loader />
            ) : error ? (
              <p>Error: {error}</p>
            ) : (
              <tbody>
                {data && data.length > 0 ? (
                  data.map((item, index) => (
                    <tr key={index}>
                      <td className="max-width-column center">
                        {item.countryName}
                      </td>
                      <td className="max-width-column center">
                        {item.languageName
                          ? `${item.languageName} ${
                              item.languageCode ? `(${item.languageCode})` : ''
                            }`
                          : 'Not found'}
                      </td>
                      <td className="max-width-column center">-</td>
                      <td className="max-width-column center">
                        <span
                          className={classNames('campaign-status-badge badge', {
                            'badge-success':
                              item.status === 'Fully/partially available',
                            'badge-warning': item.status === 'Not available'
                          })}
                        >
                          {item.status}
                        </span>
                      </td>
                      <td className="max-width-column center">
                        <ReactTooltip
                          id="viewBtnToolTip"
                          place="top"
                          type="info"
                          multiline={true}
                          className=""
                        />
                        <span
                          className="cw-icon cw-icon--help"
                          data-tip={`View`}
                          currentitem="false"
                          data-for="viewBtnToolTip"
                        >
                          <button
                            className="inline-button template-btn"
                            onClick={() => {
                              props.history.push(
                                `/campaign-wizard/consent-statement/view?country=${item.countryCode}&language=${item.languageCode}`
                              );
                            }}
                          >
                            <img
                              src={previewImg}
                              alt="preview"
                              className="preview-icon"
                            />
                          </button>
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="max-width-column center">
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default ConsentComponent;
