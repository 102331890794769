import React from 'react';
import moment from 'moment';
import { get, capitalize, compact } from 'lodash';

import { audienceOverviewDisplay } from './audienceOverviewDisplay';

import constants from 'constants/config';
import {
  DisplaySmsTriggerInput,
  DisplaySubmittedSmsTriggerInput,
  sentAfterSms
} from 'components/molecules/SmsTriggerInput';
import { couponDisplay, CouponCodeSmsDisplay } from './CouponCodeSmsDisplay';
import { SmsContentTableDisplay } from './SmsContentTableDisplay';
import {
  isConsentFieldEditable,
  userHasCouponCodePermission,
  hasPersmissionToUploadSMSCouponFile
} from 'selectors';

const getSubmissionDate = (givenDate) => {
  if(givenDate){
    let dateString = moment(givenDate).format('DD MMM YYYY');
    if(dateString === "Invalid date"){
      dateString = moment(givenDate, "DD/MM/YY - HH:mm A").format('DD MMM YYYY')
    }
    return dateString;
  }
  return '';
}

export function getSMSTabularData(
  smsCampaign,
  campaignConfig = {},
  currentUser,
  review
) {
  const {
    brand,
    brandId,
    country,
    countryId,
    language,
    name,
    smsType,
    communicationDetail = {},
    submissionDate,
    smsContentTheme,
    smsContentSecondaryTheme,
    status,
    requestorsEmail,
    audienceDefinition,
    uid,
    deliverySchedule,
    abTesting = {},
    winningVariant = null,
    smsAttributes,
    couponFileTrack = {},
    emailCollaborators = {},
    shareCostEstimate = {},
    sharePurchaseOrder = {},
    optOutText,
    nmiAssignedToRequestor
  } = smsCampaign;

  const { campaignIds, customCriteria } = audienceOverviewDisplay(
    audienceDefinition,
    campaignConfig
  );
  const { costFile, audienceCount } = shareCostEstimate;
  const { poFile } = sharePurchaseOrder;
  const showCostFileField =
    status === constants.SMS_STATUS.AWAITING_PURCHASE_ORDER ||
    status === constants.SMS_STATUS.AWAITING_SETUP ||
    status === constants.SMS_STATUS.SCHEDULED ||
    status === constants.SMS_STATUS.LIVE ||
    (status === constants.SMS_STATUS.AWAITING_INFO && !nmiAssignedToRequestor);
  const showPOFileField =
    status === constants.SMS_STATUS.AWAITING_SETUP ||
    status === constants.SMS_STATUS.SCHEDULED ||
    status === constants.SMS_STATUS.LIVE ||
    (status === constants.SMS_STATUS.AWAITING_INFO && !nmiAssignedToRequestor);

  const isFixedDateAndTime =
    deliverySchedule?.smsTrigger?.type === 'fixedDateAndtime';

  const couponApplied = smsAttributes?.smsCoupon?.couponApplied;
  const couponType = smsAttributes?.smsCoupon?.type;
  const sendAfter = get(deliverySchedule, 'sendAfter.value', false);

  const isDontSend = sendAfter === 'DontSend';
  const isCouponCodeVisible = userHasCouponCodePermission(
    requestorsEmail,
    currentUser,
    emailCollaborators
  );
  const isDownloadAllowed = hasPersmissionToUploadSMSCouponFile(
    requestorsEmail,
    currentUser,
    emailCollaborators
  );

  const SMSSecondaryTheme =
    smsContentSecondaryTheme?.map(theme => theme.label).join(', ') || '';

  const brandDetails = [];
  brandDetails.push({
    label: 'Country',
    value: countryId?.name || capitalize(country) || ''
  });
  brandDetails.push({
    label: 'Brand',
    value: brandId?.name || capitalize(brand) || ''
  });
  brandDetails.push({ label: 'SMS language', value: language });

  const SMSType = [];
  SMSType.push({
    label: 'SMS Type',
    value: constants.SMS_TYPE_LABELS[smsType]
  });

  const emailCollaboratorsData = () => {
    let collabUserData = [
      {
        label: 'Collaborators permissions',
        value:
          constants.EMAIL_COLLABORATORS_PERMISSION_TYPE[
            emailCollaborators.type
          ],
        mustShow: true
      }
    ];
    if (emailCollaborators.type === 'requestorAndSelectedUsers') {
      collabUserData.push({
        label: 'Collaborators',
        value: compact(
          emailCollaborators.collaborators.map(item => item.value)
        ).join(', ')
      });
    }
    return collabUserData;
  };

  const smsCampaignDetails = [];
  smsCampaignDetails.push({
    label: 'Campaign name',
    value: name,
    mustShow: true
  });
  smsCampaignDetails.push({
    label: 'Description',
    value: communicationDetail.smsOverview,
    mustShow: true
  });
  smsCampaignDetails.push({
    label: 'Content theme',
    value: smsContentTheme?.label,
    mustShow: true
  });
  smsCampaignDetails.push({
    label: 'Secondary theme',
    value: SMSSecondaryTheme,
    mustShow: true
  });

  if (!review) {
    smsCampaignDetails.push({
      label: 'Brand',
      value: brandId?.name || capitalize(brand) || ''
    });
    smsCampaignDetails.push({
      label: 'Country',
      value: countryId?.name || capitalize(country) || ''
    });
    smsCampaignDetails.push({
      label: 'SMS language',
      value: language
    });
  }

  smsCampaignDetails.push({
    label: 'PRA reference',
    value: communicationDetail.dpcPraReferenceNo,
    mustShow: true
  });
  smsCampaignDetails.push({
    label: 'Requestor',
    value: requestorsEmail,
    mustShow: true
  });
  if (showCostFileField && costFile && costFile.originalname) {
    smsCampaignDetails.push({
      label: 'Cost estimate',
      value: costFile.originalname,
      mustShow: true,
      tooltip:
        'Cost estimates are visible only to campaign requestor, collaborators, ACS team and admins',
      typeOfDownload: 'ce',
      isDownloadable: isDownloadAllowed
    });
  }
  if (showPOFileField && poFile && poFile.originalname) {
    smsCampaignDetails.push({
      label: 'Purchase order',
      value: poFile.originalname,
      mustShow: true,
      tooltip:
        'POs are visible only to campaign requestor, collaborators, ACS team and admins',
      typeOfDownload: 'po',
      isDownloadable: isDownloadAllowed
    });
  }

  if (submissionDate && !review) {
    smsCampaignDetails.push({
      label: 'Requested on',
      value: getSubmissionDate(submissionDate),
      mustShow: true
    });
  }

  smsCampaignDetails.push(...emailCollaboratorsData());
  if (communicationDetail && communicationDetail.additionalInfo) {
    smsCampaignDetails.push({
      label: 'Additional information',
      value: communicationDetail.additionalInfo,
      mustShow: true
    });
  }

  const audienceDetails = [];
  audienceDetails.push({
    label: 'Send SMS to',
    value: audienceDefinition?.sendSMSTo?.type?.label,
    mustShow: true
  });

  audienceDetails.push(...campaignIds);
  audienceDetails.push(...customCriteria);

  {
    audienceDefinition?.additionalInformation &&
      audienceDetails.push({
        label: 'Additional information',
        value: audienceDefinition?.additionalInformation,
        mustShow: true
      });
  }

  if (showCostFileField) {
    audienceDetails.push({
      label: 'Audience count',
      value: isFixedDateAndTime
        ? deliverySchedule?.consumerCount || audienceCount
          ? deliverySchedule?.consumerCount || audienceCount
          : 'ACS execution team will update the expected audience count when they setup the campaign'
        : 'Not available for SMS with dynamic triggers'
    });
  }

  const smsDesignProperties = [];
  if (isDontSend) {
    // no follow up
    smsDesignProperties.push({
      label: status == constants.SMS_STATUS.SUBMITTED ? 'SMS1' : 'SMS',
      // subLabel: ' (Main send)',
      value: (
        <SmsContentTableDisplay
          body={get(communicationDetail, 'smsDetails.[0].smsContent')}
          optOutText={
            status == 1
              ? optOutText
              : get(communicationDetail, 'smsDetails.[0].optOutText')
          }
          shortUrl={get(communicationDetail, 'smsDetails.[0].URL.[0].shortURL')}
        />
      ),
      mustShow: true
    });

    if (communicationDetail?.smsDetails?.[0]?.URL?.length > 0) {
      let mainShorUrl = [];
      if (
        get(communicationDetail, 'smsDetails.[0].URL.[0].shortURL') !==
        constants.SAMPLE_BITLY_URL
      ) {
        mainShorUrl.push({
          label: 'Short URL',
          // subLabel: ' (Main send)',
          value: get(communicationDetail, 'smsDetails.[0].URL.[0].shortURL')
        });
      }
      smsDesignProperties.push(
        {
          label: 'URL',
          // subLabel: ' (Main send)',
          value: get(communicationDetail, 'smsDetails.[0].URL.[0].fullURL')
        },
        ...mainShorUrl
      );
    }
  } else {
    // has follow up
    // data for main send

    smsDesignProperties.push({
      label: status == constants.SMS_STATUS.SUBMITTED ? 'SMS1' : 'SMS',
      // subLabel: ' (Main send)',
      value: (
        <SmsContentTableDisplay
          body={get(communicationDetail, 'smsDetails.[0].smsContent')}
          optOutText={
            status == 1
              ? optOutText
              : get(communicationDetail, 'smsDetails.[0].optOutText')
          }
          shortUrl={get(communicationDetail, 'smsDetails.[0].URL.[0].shortURL')}
        />
      ),
      mustShow: true
    });
    if (communicationDetail?.smsDetails?.[0]?.URL?.length > 0) {
      smsDesignProperties.push(
        {
          label: 'URL',
          // subLabel: ' (Main send)',
          value: get(communicationDetail, 'smsDetails.[0].URL.[0].fullURL')
        },
        {
          label: 'Short URL',
          // subLabel: ' (Main send)',
          value: get(communicationDetail, 'smsDetails.[0].URL.[0].shortURL')
        }
      );
    }
    // data for follow up
    if (status == constants.SMS_STATUS.SUBMITTED) {
      if (get(communicationDetail, 'smsDetails.[1].smsContent')) {
        smsDesignProperties.push({
          label: status == constants.SMS_STATUS.SUBMITTED ? 'SMS2' : 'SMS',
          // subLabel: ' (Follow up)',
          value: (
            <SmsContentTableDisplay
              body={get(communicationDetail, 'smsDetails.[1].smsContent')}
              optOutText={
                status == 1
                  ? optOutText
                  : get(communicationDetail, 'smsDetails.[1].optOutText')
              }
              shortUrl={get(communicationDetail, 'smsDetails.[1].URL.[0].shortURL')}
            />
          ),
          mustShow: true
        });
      }
    } else {
      smsDesignProperties.push({
        label: status == constants.SMS_STATUS.SUBMITTED ? 'SMS2' : 'SMS',
        // subLabel: ' (Follow up)',
        value: (
          <SmsContentTableDisplay
            body={get(communicationDetail, 'smsDetails.[1].smsContent')}
            optOutText={
              status == 1
                ? optOutText
                : get(communicationDetail, 'smsDetails.[1].optOutText')
            }
            shortUrl={get(communicationDetail, 'smsDetails.[1].URL.[0].shortURL')}
          />
        ),
        mustShow: true
      });
    }

    if (communicationDetail?.smsDetails?.[1]?.URL) {
      smsDesignProperties.push(
        {
          label: 'URL',
          subLabel: ' (Follow up)',
          value: get(communicationDetail, 'smsDetails.[1].URL.[0].fullURL')
        },
        {
          label: 'Short URL',
          subLabel: ' (Follow up)',
          value: get(communicationDetail, 'smsDetails.[1].URL.[0].shortURL')
        }
      );
    }
  }

  {
    communicationDetail?.additionalInformation &&
      smsDesignProperties.push({
        label: 'Additional information',
        value: communicationDetail?.additionalInformation,
        mustShow: true
      });
  }

  let smsTrigger =
    status === constants.SMS_STATUS.SUBMITTED
      ? DisplaySubmittedSmsTriggerInput(
          communicationDetail && communicationDetail.smsDetails
        )
      : deliverySchedule && deliverySchedule.smsTrigger
      ? DisplaySmsTriggerInput(
          deliverySchedule.smsTrigger,
          abTesting,
          winningVariant
        )
      : [
          {
            label: 'SMS trigger',
            mustShow: true,
            value: ''
          }
        ];

  // removed to hide follow up for first release
  let sendAfterArr = deliverySchedule
    ? sentAfterSms(deliverySchedule)
    : [
        {
          label: 'Send follow up',
          mustShow: true,
          value: ''
        }
      ];

  const deliveryScheduleData = [
    ...smsTrigger,
    // ...sendAfterArr,
    {
      label: 'Additional information',
      value: deliverySchedule && deliverySchedule.additionalInformation
    }
  ];

  const campaignAttributes = [
    {
      label: 'Coupons',
      value: couponDisplay(couponApplied, couponType)
    },
    {
      ...(couponApplied && {
        label: 'Coupon codes',
        value: (
          <CouponCodeSmsDisplay
            smsAttributes={smsAttributes}
            userPermitted={isCouponCodeVisible}
            couponFileTrack={couponFileTrack}
          />
        )
      })
    }
  ];

  return {
    brandDetails,
    SMSType,
    smsCampaignDetails,
    audienceDetails,
    smsDesignProperties,
    deliveryScheduleData,
    campaignAttributes
  };
}
