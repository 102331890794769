import React from 'react';
import EmailTabular from '../../components/organisms/EmailTabular/EmailTabular';
import { getTabularData } from './ConsentData';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

function ConsentDetailsTables({
  consentsData,
  country,
  language
}) {
  
  const { authorizedUserData } = useSelector(state => state.authorized);

  const currentUser = {
    email: get(authorizedUserData, 'emailId', ''),
    group: get(authorizedUserData, 'group', '')
  }

  if (!consentsData) return null;

  const {
    generalConsents,
    seperateChannelConsents,
    combinedMultiChannelConsents
  } = getTabularData(consentsData, country, language);

  return (
    <div>
      <EmailTabular
        data={generalConsents}
        title={'General consents and legal statements'}
        isConsentDetails={true}
      />
      <EmailTabular
        data={seperateChannelConsents}
        title={'Separate channel consents'}
        isConsentDetails={true}
        subText={'These are GDPR compliant consents applicable for all countries. Consumers will see a separate consent statement per channel.'}
      />
      <EmailTabular
        data={combinedMultiChannelConsents}
        title={'Combined multi-channel consents'}
        isConsentDetails={true}
        subText={'These consents are applicable for countries that are not restricted by GDPR and can be used as an alternate to separate channel consents. Consumers will see a single consent statement with multiple channels listed.'}
      />
    </div>
  );
}

export default ConsentDetailsTables;
