import React, { useState, useReducer, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import constant from '../../constants';
import ReactTooltip from 'react-tooltip';
import BreadCrumb from '../BreadCrumb';
import axios from 'axios';
import {
  setApproverEditCampaignStatus,
  setApproverEditCampaignStatusMessage,
  setApproverEditLastUpdatedScreen
} from '../../actions/app';
import { getCampaignDetails } from '../../actions/campaignDetails';
import { setNotificationMessage } from '../../actions/dashboard';
import { scrollToTop } from 'selectors';
import { cancelCampaignChanges } from '../../actions/overview';
import CampaignCancelModel from '../CampaignCancelModel';
import Modal from 'react-modal';
const regex = /^[0-9\b]+$/;
const { consentTypes  } = constant

const SaasEpsilonMasterData = props => {
  //externalinfo: "" - to be used later as part of future development

  const epsilonData = {
    dataSourceId: '',
    campaignControlId: '',
    languageQuestionAnsId: '',
    languageAnsId: '',
    languageAnsText: ''
  };
  const [matchProps, setMatchProps] = useState(props.match);
  const [campaignType, setCampaignType] = useState('');
  const [websiteType, setWebsiteType] = useState('');
  const [formType, setFormType] = useState('');
  const [isEdited, setIsEdited] = useState('');
  const [status, setStatus] = useState(undefined);
  const [showMessageAtTop, setMessageOnTop] = useState(false);
  const [epsilon, setEpsilonConfig] = useState(epsilonData);
  const [campaignData, setcampaignData] = useState({});
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const dispatch = useDispatch();

  let handleDataSource = e => {
    e.persist();

    setEpsilonConfig(prevState => {
      return { ...prevState, dataSourceId: e.target.value };
    });

    if (
      document.getElementById(`${e.target.id}_err`) &&
      !document
        .getElementById(`${e.target.id}_err`)
        .classList.contains('cw-hidden')
    ) {
      document.getElementById(`${e.target.id}_err`).classList.add('cw-hidden');

      document
        .getElementById(`${e.target.id}`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`${e.target.id}`)
          .classList.remove('cw-error-focus');
    }
    if (
      document.getElementById(`${e.target.id}`).value.trim().length === 0 ||
      regex.test(document.getElementById(`${e.target.id}`).value)
    ) {
      document
        .getElementById(`${e.target.id}`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`${e.target.id}`)
          .classList.remove('cw-error-focus');

      document.getElementById(`${e.target.id}_numErr`) &&
        document
          .getElementById(`${e.target.id}_numErr`)
          .classList.add('cw-hidden');
    } else {
      document
        .getElementById(`${e.target.id}`)
        .classList.contains('cw-error-focus') ||
        document
          .getElementById(`${e.target.id}`)
          .classList.add('cw-error-focus');

      document.getElementById(`${e.target.id}_numErr`) &&
        document
          .getElementById(`${e.target.id}_numErr`)
          .classList.remove('cw-hidden');
    }
  };
  let handleCCID = e => {
    e.persist();
    setEpsilonConfig(prevState => {
      return { ...prevState, campaignControlId: e.target.value };
    });
    if (
      document.getElementById(`${e.target.id}_err`) &&
      !document
        .getElementById(`${e.target.id}_err`)
        .classList.contains('cw-hidden')
    ) {
      document.getElementById(`${e.target.id}_err`).classList.add('cw-hidden');

      document
        .getElementById(`${e.target.id}`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`${e.target.id}`)
          .classList.remove('cw-error-focus');
    }
    if (
      document.getElementById(`${e.target.id}`).value.trim().length === 0 ||
      regex.test(document.getElementById(`${e.target.id}`).value)
    ) {
      document
        .getElementById(`${e.target.id}`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`${e.target.id}`)
          .classList.remove('cw-error-focus');

      document.getElementById(`${e.target.id}_numErr`) &&
        document
          .getElementById(`${e.target.id}_numErr`)
          .classList.add('cw-hidden');
    } else {
      document
        .getElementById(`${e.target.id}`)
        .classList.contains('cw-error-focus') ||
        document
          .getElementById(`${e.target.id}`)
          .classList.add('cw-error-focus');

      document.getElementById(`${e.target.id}_numErr`) &&
        document
          .getElementById(`${e.target.id}_numErr`)
          .classList.remove('cw-hidden');
    }
  };
  let handleBrandCCID = e => {
    e.persist();
    setEpsilonConfig(prevState => {
      return { ...prevState, brandOptinCCID: e.target.value };
    });
    if (
      document.getElementById(`${e.target.id}_err`) &&
      !document
        .getElementById(`${e.target.id}_err`)
        .classList.contains('cw-hidden')
    ) {
      document.getElementById(`${e.target.id}_err`).classList.add('cw-hidden');

      document
        .getElementById(`${e.target.id}`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`${e.target.id}`)
          .classList.remove('cw-error-focus');
    }
  };

  let handleBrandSmsCCID = e => {
    e.persist();
    setEpsilonConfig(prevState => {
      return { ...prevState, brandSmsOptinCCID: e.target.value };
    });
    if (
      document.getElementById(`${e.target.id}_err`) &&
      !document
        .getElementById(`${e.target.id}_err`)
        .classList.contains('cw-hidden')
    ) {
      document.getElementById(`${e.target.id}_err`).classList.add('cw-hidden');

      document
        .getElementById(`${e.target.id}`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`${e.target.id}`)
          .classList.remove('cw-error-focus');
    }
  };

  let handleCorporateCCID = e => {
    e.persist();
    setEpsilonConfig(prevState => {
      return { ...prevState, corporateOptinCCID: e.target.value };
    });
    if (
      document.getElementById(`${e.target.id}_err`) &&
      !document
        .getElementById(`${e.target.id}_err`)
        .classList.contains('cw-hidden')
    ) {
      document.getElementById(`${e.target.id}_err`).classList.add('cw-hidden');

      document
        .getElementById(`${e.target.id}`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`${e.target.id}`)
          .classList.remove('cw-error-focus');
    }
  };
  let handleQAId = e => {
    e.persist();
    setEpsilonConfig(prevState => {
      return { ...prevState, languageQuestionAnsId: e.target.value };
    });
    if (
      document.getElementById(`${e.target.id}_err`) &&
      !document
        .getElementById(`${e.target.id}_err`)
        .classList.contains('cw-hidden')
    ) {
      document.getElementById(`${e.target.id}_err`).classList.add('cw-hidden');
    }
    if (
      document.getElementById(`${e.target.id}`).value.trim().length === 0 ||
      regex.test(document.getElementById(`${e.target.id}`).value)
    ) {
      document
        .getElementById(`${e.target.id}`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`${e.target.id}`)
          .classList.remove('cw-error-focus');

      document.getElementById(`${e.target.id}_numErr`) &&
        document
          .getElementById(`${e.target.id}_numErr`)
          .classList.add('cw-hidden');
    } else {
      document
        .getElementById(`${e.target.id}`)
        .classList.contains('cw-error-focus') ||
        document
          .getElementById(`${e.target.id}`)
          .classList.add('cw-error-focus');

      document.getElementById(`${e.target.id}_numErr`) &&
        document
          .getElementById(`${e.target.id}_numErr`)
          .classList.remove('cw-hidden');
    }
  };
  let handleAnsweId = e => {
    e.persist();
    setEpsilonConfig(prevState => {
      return { ...prevState, languageAnsId: e.target.value };
    });
    if (
      document.getElementById(`${e.target.id}_err`) &&
      !document
        .getElementById(`${e.target.id}_err`)
        .classList.contains('cw-hidden')
    ) {
      document.getElementById(`${e.target.id}_err`).classList.add('cw-hidden');
    }
    if (
      document.getElementById(`${e.target.id}`).value.trim().length === 0 ||
      regex.test(document.getElementById(`${e.target.id}`).value)
    ) {
      document
        .getElementById(`${e.target.id}`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`${e.target.id}`)
          .classList.remove('cw-error-focus');

      document.getElementById(`${e.target.id}_numErr`) &&
        document
          .getElementById(`${e.target.id}_numErr`)
          .classList.add('cw-hidden');
    } else {
      document
        .getElementById(`${e.target.id}`)
        .classList.contains('cw-error-focus') ||
        document
          .getElementById(`${e.target.id}`)
          .classList.add('cw-error-focus');

      document.getElementById(`${e.target.id}_numErr`) &&
        document
          .getElementById(`${e.target.id}_numErr`)
          .classList.remove('cw-hidden');
    }
  };
  let handleAnswerText = e => {
    e.persist();
    setEpsilonConfig(prevState => {
      return { ...prevState, languageAnsText: e.target.value };
    });

    if (
      document.getElementById(`${e.target.id}_err`) &&
      !document
        .getElementById(`${e.target.id}_err`)
        .classList.contains('cw-hidden')
    ) {
      document.getElementById(`${e.target.id}_err`).classList.add('cw-hidden');

      document
        .getElementById(`${e.target.id}`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`${e.target.id}`)
          .classList.remove('cw-error-focus');
    }
  };

  useEffect(() => {
    dispatch(getCampaignDetails(props.match.params.id));
    let campaignId = props.match.params.id;
    axios
      .get(constant.serviceUrls.CAMPAIGN_DETAILS_SERVICE_URL + '/' + campaignId)
      .then(response => {
        let { campaignType, type, websiteType, isEdited } =
          response && response.data[0];
        setCampaignType(campaignType);
        setWebsiteType(websiteType.name);
        setIsEdited(isEdited);
        setFormType(type);
        let status = response && response.data[0] && response.data[0].status;
        let campaignDetail =
          response && response.data[0] && response.data[0].campaignDetail;
        setcampaignData(campaignDetail);
        let epsilon = campaignDetail && campaignDetail.epsilon;
        if (epsilon !== undefined) {
          setEpsilonConfig(epsilon);
        }
        setStatus(status);
      });
    return () => {};
  }, [dispatch, props.match.params.id]);

  let validateFields = fieldObj => {
   /* let isConsentTypeBrand =
      campaignData &&
      campaignData.consents.filter(
        elem => elem.optInType === 'brandEmailConsent'
      );
    let isConsentTypeUnilever =
      campaignData &&
      campaignData.consents.filter(
        elem => elem.optInType === 'unileverEmailConsent'
      );

    let isCombinedConsent =
      campaignData &&
      campaignData.consents.filter(
        elem => elem.optInType === 'combinedUnileverAndBrandEmailConsent'
    );*/
    let isValid = true;
    let id = fieldObj && Object.keys(fieldObj);
    let epsilonMasterData = id;

    /*if ((isConsentTypeBrand.length === 1 && isConsentTypeUnilever.length === 1) || isCombinedConsent.length) {
      epsilonMasterData = id;
    }
    if (isConsentTypeBrand.length === 1 && isConsentTypeUnilever.length === 0) {
      epsilonMasterData = id.filter(el => el !== 'corporateOptinCCID');
    }
    if (isConsentTypeUnilever.length === 1 && isConsentTypeBrand.length === 0) {
      epsilonMasterData = id.filter(el => el !== 'brandOptinCCID');
    }*/

    if (
      document.getElementById(`languageQuestionAnsId`).value.trim().length != 0
    ) {
      if (document.getElementById(`languageAnsId`).value.trim().length === 0) {
        isValid = false;

        document.getElementById(`languageAnsId_err`) &&
          document
            .getElementById(`languageAnsId_err`)
            .classList.contains('cw-hidden') &&
          document
            .getElementById(`languageAnsId_err`)
            .classList.remove('cw-hidden');

        document
          .getElementById(`languageAnsId`)
          .classList.contains('cw-error-focus') ||
          document
            .getElementById(`languageAnsId`)
            .classList.add('cw-error-focus');
      }
    } else if (
      document.getElementById(`languageAnsId`).value.trim().length != 0
    ) {
      if (
        document.getElementById(`languageQuestionAnsId`).value.trim().length ===
        0
      ) {
        isValid = false;
        document.getElementById(`languageQuestionAnsId_err`) &&
          document
            .getElementById(`languageQuestionAnsId_err`)
            .classList.contains('cw-hidden') &&
          document
            .getElementById(`languageQuestionAnsId_err`)
            .classList.remove('cw-hidden');

        document
          .getElementById(`languageQuestionAnsId`)
          .classList.contains('cw-error-focus') ||
          document
            .getElementById(`languageQuestionAnsId`)
            .classList.add('cw-error-focus');
      }
    }

   /* if (
      document.getElementById(`corporateOptinCCID`) &&
      document.getElementById(`corporateOptinCCID`).value.trim().length != 0 &&
      !regex.test(document.getElementById(`corporateOptinCCID`).value)
    ) {
      document.getElementById(`corporateOptinCCID_numErr`) &&
        document
          .getElementById(`corporateOptinCCID_numErr`)
          .classList.remove('cw-hidden');
      document
        .getElementById(`corporateOptinCCID`)
        .classList.contains('cw-error-focus') ||
        document
          .getElementById(`corporateOptinCCID`)
          .classList.add('cw-error-focus');
      isValid = false;
    } else {
      document
        .getElementById(`corporateOptinCCID_numErr`)
        .classList.add('cw-hidden');
      document
        .getElementById(`corporateOptinCCID`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`corporateOptinCCID`)
          .classList.remove('cw-error-focus');
    }
    if (
      document.getElementById(`brandOptinCCID`) &&
      document.getElementById(`brandOptinCCID`).value.trim().length != 0 &&
      !regex.test(document.getElementById(`brandOptinCCID`).value)
    ) {
      document.getElementById(`brandOptinCCID_numErr`) &&
        document
          .getElementById(`brandOptinCCID_numErr`)
          .classList.remove('cw-hidden');

      document
        .getElementById(`brandOptinCCID`)
        .classList.contains('cw-error-focus') ||
        document
          .getElementById(`brandOptinCCID`)
          .classList.add('cw-error-focus');
      isValid = false;
    } else {
      document
        .getElementById(`brandOptinCCID_numErr`)
        .classList.add('cw-hidden');

      document
        .getElementById(`brandOptinCCID`)
        .classList.contains('cw-error-focus') &&
        document
          .getElementById(`brandOptinCCID`)
          .classList.remove('cw-error-focus');
    }*/

    for (let i = 0; i < epsilonMasterData.length; i++) {
      if (
        epsilonMasterData[i] !== 'languageAnsText' &&
        epsilonMasterData[i] !== 'languageQuestionAnsId' &&
        epsilonMasterData[i] !== 'languageAnsId' &&
        document.getElementById(`${epsilonMasterData[i]}`) &&
        document.getElementById(`${epsilonMasterData[i]}`).value.trim()
          .length === 0
      ) {
        if (
          document.getElementById(`${epsilonMasterData[i]}_err`) &&
          document
            .getElementById(`${epsilonMasterData[i]}_err`)
            .classList.contains('cw-hidden')
        ) {
          document
            .getElementById(`${epsilonMasterData[i]}_err`)
            .classList.remove('cw-hidden');
        }

        document
          .getElementById(`${epsilonMasterData[i]}`)
          .classList.contains('cw-error-focus') ||
          document
            .getElementById(`${epsilonMasterData[i]}`)
            .classList.add('cw-error-focus');

        isValid = false;
      }

      document.getElementById(`${epsilonMasterData[i]}_numErr`) &&
        (document
          .getElementById(`${epsilonMasterData[i]}_numErr`)
          .classList.contains('cw-hidden') ||
          document
            .getElementById(`${epsilonMasterData[i]}_numErr`)
            .classList.add('cw-hidden'));
      if (
        epsilonMasterData &&
        epsilonMasterData[i] != 'languageAnsText' &&
        document.getElementById(`${epsilonMasterData[i]}`) &&
        document.getElementById(`${epsilonMasterData[i]}`).value.trim()
          .length != 0 &&
        !regex.test(document.getElementById(`${epsilonMasterData[i]}`).value)
      ) {
        document.getElementById(`${epsilonMasterData[i]}_numErr`) &&
          document
            .getElementById(`${epsilonMasterData[i]}_numErr`)
            .classList.contains('cw-hidden') &&
          document
            .getElementById(`${epsilonMasterData[i]}_numErr`)
            .classList.remove('cw-hidden');

        document
          .getElementById(`${epsilonMasterData[i]}`)
          .classList.contains('cw-error-focus') ||
          document
            .getElementById(`${epsilonMasterData[i]}`)
            .classList.add('cw-error-focus');

        isValid = false;
      }
    }
    return isValid;
  };

  let handlebackButton = event => {
    event.preventDefault();
    let { campaign } = props;
    let campaignId = props.match.params.id;
    props.history.push(
      '/campaign-wizard/campaign/' + campaignId + '/saas-sorting-page'
    );
  };

  let handleSubmit = async (event, saveAsDraft) => {
    event.preventDefault();
    let isValid = true;
    let campaignId = props.match.params.id;
    let epsilonData = {
      ...epsilon,
      dataSourceId:
        document.getElementById(`dataSourceId`) &&
        document.getElementById(`dataSourceId`).value.trim(),
      campaignControlId:
        document.getElementById(`campaignControlId`) &&
        document.getElementById(`campaignControlId`).value.trim(),
      languageQuestionAnsId:
        document.getElementById(`languageQuestionAnsId`) &&
        document.getElementById(`languageQuestionAnsId`).value.trim(),
      languageAnsId:
        document.getElementById(`languageAnsId`) &&
        document.getElementById(`languageAnsId`).value.trim(),
      languageAnsText:
        document.getElementById(`languageAnsText`) &&
        document.getElementById(`languageAnsText`).value.trim()
    };
  

    let dataToSend = {
      uid: campaignId,
      epsilon : epsilonData
    };

    if (isEdited) {
      dataToSend = {
        ...dataToSend,
        isEdited: true
      };
    }

    if (saveAsDraft) {
      dataToSend['saveAsDraft'] = saveAsDraft;
    } else {
      isValid = validateFields(epsilonData);
      if (!isValid) {
        setMessageOnTop(true);
        scrollToTop();
        return isValid;
      }
    }

    await axios
      .post(constant.serviceUrls.CAMPAIGN_DATA_SERVICE_URL, dataToSend)
      .then(response => {
        if (response) {
          if (saveAsDraft) {
            props.history.push('/campaign-wizard/dashboard');
          } else {
            setMessageOnTop(false);
            props.history.push(
              '/campaign-wizard/campaign/' + campaignId + '/review'
            );
          }
        }
      });
  };

  let _handleCancel = () => {
    setApproverEditCampaignStatus(false);
    setApproverEditCampaignStatusMessage('');
    props.history.push(
      '/campaign-wizard/campaign/' + props.match.params.id + '/approval'
    );
  };

  const _openIsCancelModal = event => {
    event.preventDefault();
    setIsCancelModalOpen(true);
  };

  const _closeIsCancelModal = () => {
    setIsCancelModalOpen(false);
  };
  const _cancelSaasCampaign = event => {
    event.preventDefault();
    setIsCancelModalOpen(false);
    cancelCampaignChanges(props.match.params.id);
    props.history.push('/campaign-wizard/campaign/' + props.match.params.id);
  };

  const selector = useSelector(state => ({
    campaign: state.campaignDetails.campaign
  }));

  if(!campaignData.consents) return null
  const isbrandSmsEnableForUS = campaignData && campaignData.consents.find(e=> e.optInType === consentTypes.brandSMSConsent)

  return (
    <div className="col-sm-12">
      <ReactTooltip
        place="right"
        type="info"
        multiline={true}
        className="cw-tooltip"
      />
      <Modal
        scrollable={true}
        isOpen={isCancelModalOpen}
        onRequestClose={_closeIsCancelModal}
        className="cw-modal cw-modal--branddetail"
        contentLabel="Campaign Wizard 2.0"
        style={{
          overlay: {
            backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
          }
        }}
      >
        <CampaignCancelModel
          closeIsCancelModal={_closeIsCancelModal}
          isCancelContinue={_cancelSaasCampaign}
        />
      </Modal>
      <div className="cw-section ml-20">
        <div className="ml-2">
          <BreadCrumb
            match={matchProps}
            isEpsilonMarket={true}
            editCampaign={props.editCampaign || props.isCampaignEdited}
            hideBreadCrumb={props.approverEditCampaign || isEdited}
            campaignType={campaignType}
            websiteType={websiteType}
            formType={formType}
          />
        </div>
        <div className="cw-section--title mb-20">
          <h2
            className="cw-heading--secondary mb-20 ml-10"
            style={{ marginLeft: '4px' }}
          >
            Epsilon master data
          </h2>
          <div
            className={
              showMessageAtTop
                ? 'alert alert-danger ml-2'
                : 'alert alert-danger cw-hidden'
            }
            role="alert"
          >
            <strong>
              There was a problem with the page. Errors are listed below{' '}
            </strong>
          </div>
          <div className="row">
            <div className="col-sm-7">
              <div className="cw-section--content">
                <div className="form-group">
                  <div className="form-group form-group-field-width ml-20">
                    <p>
                    Please enter the master data IDs from the Campaign Template (CT) you received from Epsilon.
                    </p>
                  </div>
                  <div className="form-group form-group-field-width" key={`1`}>
                    <label htmlFor={``}>
                      <span>Data Source ID</span>
                      <span
                        className="float-right  cw-icon cw-icon--help"
                        data-tip=""
                      >
                        <i className="fas fa-question-circle"></i>
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name={`dataSourceId`}
                      id={`dataSourceId`}
                      maxLength={16}
                      aria-describedby={``}
                      data-required="true"
                      defaultValue={epsilon && epsilon.dataSourceId}
                      onChange={handleDataSource}
                    />

                    <span
                      id={`dataSourceId_err`}
                      className="cw-error cw-hidden"
                      data-control="dataSourceId"
                    >
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please enter Data Source ID
                    </span>
                    <span
                      id={`dataSourceId_numErr`}
                      className="cw-error cw-hidden"
                      data-control="languageQuestionAnsId"
                    >
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please enter a numeric value
                    </span>
                  </div>
                  <div className="form-group form-group-field-width" key={`2`}>
                    <label htmlFor={``}>
                      <span>Campaign Control ID (CCID)</span>
                      <span
                        className="float-right  cw-icon cw-icon--help"
                        data-tip=""
                      >
                        <i className="fas fa-question-circle"></i>
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name={`campaignControlId`}
                      id={`campaignControlId`}
                      aria-describedby={``}
                      data-required="true"
                      defaultValue={epsilon.campaignControlId}
                      onChange={handleCCID}
                      maxLength={16}
                    />

                    <span
                      id={`campaignControlId_err`}
                      className="cw-error cw-hidden"
                      data-control="campaignControlId"
                    >
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please enter Campaign Control ID (CCID)
                    </span>
                    <span
                      id={`campaignControlId_numErr`}
                      className="cw-error cw-hidden"
                      data-control="languageQuestionAnsId"
                    >
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please enter a numeric value
                    </span>
                  </div>
                   {/*
                  <div className="form-group form-group-field-width" key={`3`}>
                    <label htmlFor={``}>
                      <span>Brand Opt-in CCID (Email)</span>
                      <span
                        className="float-right  cw-icon cw-icon--help"
                        data-tip=""
                      >
                        <i className="fas fa-question-circle"></i>
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name={`brandOptinCCID`}
                      id={`brandOptinCCID`}
                      aria-describedby={``}
                      data-required="true"
                      defaultValue={epsilon.brandOptinCCID}
                      onChange={handleBrandCCID}
                      maxLength={16}
                    />

                    <span
                      id={`brandOptinCCID_err`}
                      className="cw-error cw-hidden"
                      data-control="brandOptinCCID"
                    >
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please enter Brand Opt-in CCID
                    </span>
                    <span
                      id={`brandOptinCCID_numErr`}
                      className="cw-error cw-hidden"
                      data-control="languageQuestionAnsId"
                    >
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please enter a numeric value
                    </span>
                  </div>
                  <div className="form-group form-group-field-width" key={`4`}>
                    <label htmlFor={``}>
                      <span>Corporate Opt-in CCID (Email)</span>
                      <span
                        className="float-right  cw-icon cw-icon--help"
                        data-tip=""
                      >
                        <i className="fas fa-question-circle"></i>
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name={`corporateOptinCCID`}
                      id={`corporateOptinCCID`}
                      aria-describedby={``}
                      data-required="true"
                      maxLength={16}
                      defaultValue={epsilon.corporateOptinCCID}
                      onChange={handleCorporateCCID}
                    />

                    <span
                      id={`corporateOptinCCID_err`}
                      className="cw-error cw-hidden"
                      data-control="corporateOptinCCID"
                    >
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please enter Corporate Opt-in CCID
                    </span>
                    <span
                      id={`corporateOptinCCID_numErr`}
                      className="cw-error cw-hidden"
                      data-control="languageQuestionAnsId"
                    >
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please enter a numeric value
                    </span>
                  </div> 
                  {isbrandSmsEnableForUS && (
                     <div className="form-group form-group-field-width" key={`8`}>
                     <label htmlFor={``}>
                       <span>Brand Opt-in CCID (SMS)</span>
                       <span
                         className="float-right  cw-icon cw-icon--help"
                         data-tip=""
                       >
                         <i className="fas fa-question-circle"></i>
                       </span>
                     </label>
                     <input
                       type="text"
                       className="form-control"
                       name={`brandSmsOptinCCID`}
                       id={`brandSmsOptinCCID`}
                       aria-describedby={``}
                       data-required="true"
                       defaultValue={epsilon.brandSmsOptinCCID}
                       onChange={handleBrandSmsCCID}
                       maxLength={16}
                     />
 
                     <span
                       id={`brandSmsOptinCCID_err`}
                       className="cw-error cw-hidden"
                       data-control="brandSmsOptinCCID"
                     >
                       <i className="fas fa-exclamation-triangle mr-10"></i>
                       Please enter Brand SMS Opt-in CCID
                     </span>
                     <span
                       id={`brandSmsOptinCCID_numErr`}
                       className="cw-error cw-hidden"
                       data-control="languageQuestionAnsId"
                     >
                       <i className="fas fa-exclamation-triangle mr-10"></i>
                       Please enter a numeric value
                     </span>
                   </div>
                  )}*/}
                  <div className="form-group form-group-field-width" key={`5`}>
                    <label htmlFor={``}>
                      <span>Language Question Answer ID</span>
                      <span
                        className="float-right  cw-icon cw-icon--help"
                        data-tip=""
                      >
                        <i className="fas fa-question-circle"></i>
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name={`languageQuestionAnsId`}
                      id={`languageQuestionAnsId`}
                      aria-describedby={``}
                      data-required="true"
                      maxLength={16}
                      defaultValue={epsilon.languageQuestionAnsId}
                      onChange={handleQAId}
                    />
                    <span
                      id={`languageQuestionAnsId_err`}
                      className="cw-error cw-hidden"
                      data-control="languageQuestionAnsId"
                    >
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please specify both Language Answer ID and Language
                      Question Answer ID
                    </span>
                    <span
                      id={`languageQuestionAnsId_numErr`}
                      className="cw-error cw-hidden"
                      data-control="languageQuestionAnsId"
                    >
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please enter a numeric value
                    </span>
                  </div>
                  <div className="form-group form-group-field-width" key={`6`}>
                    <label htmlFor={``}>
                      <span>Language Answer ID</span>
                      <span
                        className="float-right  cw-icon cw-icon--help"
                        data-tip=""
                      >
                        <i className="fas fa-question-circle"></i>
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name={`languageAnsId`}
                      id={`languageAnsId`}
                      aria-describedby={``}
                      data-required="true"
                      maxLength={16}
                      defaultValue={epsilon.languageAnsId}
                      onChange={handleAnsweId}
                    />
                    <span
                      id={`languageAnsId_err`}
                      className="cw-error cw-hidden"
                      data-control="languageAnsId"
                    >
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please specify both Language Answer ID and Language
                      Question Answer ID
                    </span>
                    <span
                      id={`languageAnsId_numErr`}
                      className="cw-error cw-hidden"
                      data-control="languageAnsId"
                    >
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please enter a numeric value
                    </span>
                  </div>
                  <div className="form-group form-group-field-width" key={`7`}>
                    <label htmlFor={``}>
                      <span>Language Answer Text</span>
                      <span
                        className="float-right  cw-icon cw-icon--help"
                        data-tip=""
                      >
                        <i className="fas fa-question-circle"></i>
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name={`languageAnsText`}
                      id={`languageAnsText`}
                      aria-describedby={``}
                      data-required="true"
                      // maxLength={24}
                      defaultValue={epsilon.languageAnsText}
                      onChange={handleAnswerText}
                    />
                    <span
                      id={`languageAnsText_err`}
                      className="cw-error cw-hidden"
                      data-control="languageAnsText"
                    >
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please enter Language Answer Text
                    </span>
                    <span
                      id={`languageAnsText_numErr`}
                      className="cw-error cw-hidden"
                      data-control="languageAnsText"
                    >
                      <i className="fas fa-exclamation-triangle mr-10"></i>
                      Please enter a numeric value
                    </span>
                  </div>
                  <div className='ml-10'>
                    <strong>Note:&nbsp;</strong>
                    Please note that Question & Answer specific IDs and CCIDs for marketing consents need to be added in the respective fields on the previous field configuration page.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {status === '6' ? (
          <div className="cw-form--action-cta ml-10">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Save
            </button>
            <a
              href="javascript:void(0)"
              className="cw-save-to-draft mt-40"
              style={{ width: '40%' }}
              onClick={_handleCancel}
            >
              Cancel
            </a>
          </div>
        ) : (
          <div className="cw-form--action-cta ml-10">
            <button
              type="button"
              className="btn btn btn-outline-secondary mr-3"
              onClick={handlebackButton}
            >
              Back
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Next
            </button>
            {(campaignData && campaignData.isEdited) || isEdited ? (
              <a
                href="javascript:void(0)"
                className="cw-cancel-edit mt-40"
                onClick={event => _openIsCancelModal(event)}
              >
                Cancel changes
              </a>
            ) : (
              <a
                href="javascript:void(0)"
                className="cw-save-to-draft mt-40"
                style={{ width: '40%' }}
                onClick={event => handleSubmit(event, true)}
              >
                Save and exit
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SaasEpsilonMasterData;
