import React from 'react';
import ReactTooltip from 'react-tooltip';
import ErrorIcon from '../../atoms/ErrorIcon/ErrorIcon';
import { epsilonMarket } from 'selectors'

class SubmitButton extends React.Component {
  constructor(props) {
    super(props);
    this.boolean = {
      true: true,
      false: false
    };
    this.state = {
      matchProps: this.props.match,
      isCancelModalOpen: false
    };
    this._openIsCancelModal = this._openIsCancelModal.bind(this);
    this._closeIsCancelModal = this._closeIsCancelModal.bind(this);
    this._cancelSaasCampaign = this._cancelSaasCampaign.bind(this);
  }

  _openIsCancelModal(event) {
    event.preventDefault();
    this.setState({
      isCancelModalOpen: true
    });
  }

  _closeIsCancelModal() {
    this.setState({
      isCancelModalOpen: false
    });
  }

  _cancelSaasCampaign(event) {
    event.preventDefault();
    this.setState({
      isCancelModalOpen: false
    });
    this.props.cancelCampaignChanges(this.props.campaignId);
    this.props.history.push(
      '/campaign-wizard/campaign/' + this.props.campaignId
    );
  }

  render() {
    const {
      elem,
      index,
      handleAccordionExpandCollapse,
      campaign,
      expand,
      isValid,
      submitCtaLabel,
      failureMsg,
      expiryMsg,
      mandatoryInd,
      _handleMandatoryIndText,
      _handleSubmitCtaLabel,
      _handleFailureMsg,
      _handleExpiryMsg,
      instantWinLose,
      winLooseOnchange
    } = this.props;

    const isEpsilonMarket = epsilonMarket(campaign?.country?.code) || false

    const isIncentiveCompetition =
      campaign &&
      campaign.campaignType === 'Incentive' &&
      campaign.incentiveType === 'Competitions';

    const isMasterDataOther =
      campaign &&
      campaign.isMasterData &&
      campaign.campaignDetail &&
      campaign.campaignDetail.incentiveItPartner === 'other'
        ? true
        : false;
    return (
      <div
        id={`accordion_${elem.value}_${index + 1}`}
        className="accordion saas-form-field-accordion field"
        key={`${elem.label}_${index + 1}`}
      >
        <ReactTooltip
          place="right"
          type="info"
          multiline={true}
          className="cw-tooltip"
        />
        {/* <Modal
            isOpen={isModalOpen}
            onRequestClose={_closeModal}
            className="cw-modal cw-modal--branddetail"
            contentLabel="Can't find your brand?"
          >
            <button className="cw-modal--close" title="Close" onClick={_closeModal}>
              <i className="fas fa-times"></i>
            </button>
    
            <p className="mb-10">
              We recommend you use this field to let your users know the valid file
              types and size upfront, based on your selection. For example, add a
              message "You can only upload jpg and png files of upto 10000KB".
            </p>
          </Modal> */}

        <ErrorIcon isValid={isValid} />
        <div className="card" style={{ border: 0, overflow: 'visible' }}>
          <div className="card-header">
            <span
              className="saas-accordion-heading cw-color--primary"
              style={{ fontWeight: '700' }}
            >
              {elem.label
                ? `${elem.label}${
                    isIncentiveCompetition
                      ? isMasterDataOther
                        ? ''
                        : ' (Instant win/lose)'
                      : ''
                  }`
                : 'Submit button'}
            </span>
            <span
              type="button"
              className={`saas-accordion-arrow ${index}`}
              onClick={() => handleAccordionExpandCollapse(index)}
            >
              <i
                className={
                  expand === index
                    ? 'fas fa-chevron-down transformIcon-180'
                    : 'fas fa-chevron-down'
                }
              ></i>
            </span>
          </div>
          <div className={expand === index ? 'collapse show' : 'collapse'}>
            <ReactTooltip
              place="right"
              type="info"
              multiline={true}
              className="cw-tooltip"
            />
            <>
              <form className="cw-form" onSubmit={this._handleSubmit}>
                <div className="form-group form-group-field-width">
                  <label htmlFor="submitCtaLabel">
                    <span>Submit Button Label</span>
                    <span
                      className="float-right  cw-icon cw-icon--help"
                      data-tip="Creating a call to action will allow users to click through. Make sure this label is welcoming for your users who fill up the form."
                    >
                      <i className="fas fa-question-circle"></i>
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="submitCtaLabel"
                    id="submitCtaLabel"
                    maxLength="50"
                    value={submitCtaLabel}
                    ref="submitCtaLabel"
                    aria-describedby="submitsubmitCtaLabel"
                    data-required="true"
                    onChange={_handleSubmitCtaLabel}
                  />
                  <span
                    className="cw-error cw-hidden"
                    data-control="submitCtaLabel"
                  >
                    <i className="fas fa-exclamation-triangle mr-10"></i>
                    Please enter the submit button label.
                  </span>
                </div>
                <div className="form-group form-group-field-width">
                  <label htmlFor="failureMsg">
                    <span>Submission Failure Message</span>
                    <span
                      className="float-right  cw-icon cw-icon--help"
                      data-tip="Please specify the failure message you would want users to see if the form fails to submit due to technical issue."
                    >
                      <i className="fas fa-question-circle"></i>
                    </span>
                  </label>
                  <textarea
                    className="form-control"
                    name="failureMsg"
                    id="failureMsg"
                    ref="failureMsg"
                    rows="3"
                    value={failureMsg}
                    maxLength="500"
                    aria-describedby="submissionfailureMsg"
                    data-required="true"
                    onChange={_handleFailureMsg}
                  ></textarea>
                  <span
                    className="cw-error cw-hidden"
                    data-control="failureMsg"
                  >
                    <i className="fas fa-exclamation-triangle mr-10"></i>
                    Please enter the submission failure message.
                  </span>
                </div>
                <div className="form-group form-group-field-width">
                  <label htmlFor="expiryMsg">
                    <span>Campaign Expiry Message</span>
                    <span
                      className="float-right  cw-icon cw-icon--help"
                      data-tip="Please specify the expiry message you would want the users to see when they try to access an expired campaign."
                    >
                      <i className="fas fa-question-circle"></i>
                    </span>
                  </label>
                  <textarea
                    className="form-control"
                    name="expiryMsg"
                    id="expiryMsg"
                    ref="expiryMsg"
                    rows="3"
                    value={expiryMsg}
                    maxLength="500"
                    aria-describedby="campaignExpiryMsg"
                    data-required="true"
                    onChange={_handleExpiryMsg}
                  ></textarea>
                  <span className="cw-error cw-hidden" data-control="expiryMsg">
                    <i className="fas fa-exclamation-triangle mr-10"></i>
                    Please enter the campaign expiry message.
                  </span>
                </div>
                {isIncentiveCompetition ? (
                  isMasterDataOther ? null : (
                    <div className="form-group form-group-field-width">
                      <label>
                        <span>Competition instant win/loss</span>
                        <span
                          className="float-right  cw-icon cw-icon--help"
                          data-tip={
                            'If instant win loss is enabled, once users submit the sign up form, they will need to see whether they have won or lost and would require you to create respective pages. <br />' +
                            'Please note that the URLs to win/loss pages and the win/loss messages will need to be configured in your respective web platforms and will have to be referenced while embedding the form widget.'
                          }
                        >
                          <i className="fas fa-question-circle"></i>
                        </span>
                      </label>
                      <div className="d-flex">
                        <label className="switch-saas">
                          <input
                            type="checkbox"
                            checked={instantWinLose}
                            value={
                              instantWinLose ? this.state.instantWinLose : false
                            }
                            name={`compititionDetails_`}
                            id={`compititionDetails_`}
                            onChange={winLooseOnchange}
                          />
                          <div className="slider-saas round"></div>
                        </label>
                        <label
                          className="ml-20"
                          htmlFor={`compititionDetails_`}
                        >
                          Do you want users to instantly see whether they have
                          won the competition or not?
                        </label>
                      </div>
                    </div>
                  )
                ) : null}
                <div className="form-group form-group-field-width">
                  <label htmlFor="mandatoryInd">
                    <span>Mandatory Fields Indicator Text</span>
                    <span
                      className="float-right  cw-icon cw-icon--help"
                      data-tip="Please specify the mandatory fields indicator text to be displayed in the form, informing the user about the mandatory fields."
                    >
                      <i className="fas fa-question-circle"></i>
                    </span>
                  </label>
                  <textarea
                    className="form-control"
                    name="mandatoryInd"
                    id="mandatoryInd"
                    ref="mandatoryInd"
                    value={mandatoryInd}
                    rows="3"
                    maxLength="500"
                    aria-describedby="MandatoryIndText"
                    data-required="true"
                    onChange={_handleMandatoryIndText}
                  ></textarea>
                  <span
                    className="cw-error cw-hidden"
                    data-control="mandatoryInd"
                  >
                    <i className="fas fa-exclamation-triangle mr-10"></i>
                    Please enter the mandatory fields indicator text.
                  </span>
                </div>
                
                {/* {isEpsilonMarket && <EpsilonIDFields 
                  campaignId={this.props.campaignId} 
                  dataSourceId={this.props.dataSourceId}
                  campaignControlId={this.props.campaignControlId}
                  languageQuestionAnsId={this.props.languageQuestionAnsId}
                  languageAnsId={this.props.languageAnsId}
                  languageAnsText={this.props.languageAnsText}
                  _handleDataSource={this.props._handleDataSource}
                  _handlecampaignControlId={this.props._handlecampaignControlId}
                  _handleLanguageQuestionAnsId={this.props._handleLanguageQuestionAnsId}
                  _handleLanguageAnsId={this.props._handleLanguageAnsId}
                  _handleLanguageAnsText={this.props._handleLanguageAnsText}
                />} */}
              </form>
            </>
          </div>
        </div>
      </div>
    );
  }
}

export default SubmitButton;
