import {
  FIELD_SORT_GET_CAMPAIGN,
  FIELD_SORT_ASYNC_IN_PROGRESS,
  UDPATE_PROFILE_FIELDS,
  UPDATE_GENDER_VALUES,
  UPDATE_PROVINCE_VALUES
} from "../actionTypes";

const initialState = {
  asyncInProgress: false,
  campaign: {},
  campaignDetails: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FIELD_SORT_GET_CAMPAIGN: {
      let campaignDetail = action.campaign.campaignDetail;
      let genderValues = [];
      let provinceValues = [];
      if (campaignDetail && campaignDetail.fields) {
        let genderObj = campaignDetail.fields.filter(
          (e) => e.dataType === "gender"
        );
        if (genderObj && genderObj.length > 0) {
          genderValues =
            genderObj[0].values &&
            genderObj[0].values.filter((e) => e.isChecked === true);
        }
        let provinceObj = campaignDetail.fields.filter(
          (e) => e.dataType === "stateOrProvince"
        );
        if (provinceObj && provinceObj.length > 0) {
          provinceValues = provinceObj[0].answers;
        }
        
      }

      let profileAndConsentField = []
      if(campaignDetail && campaignDetail.fields && campaignDetail.fields.length){
        profileAndConsentField =  campaignDetail.fields
      }

       let privacyAndDesclaimer = []
      /*let consentField = []
      if(campaignDetail && campaignDetail.consents){
        for(const ele of campaignDetail.consents){
          if(ele.optInType === 'privacyNotice' || ele.optInType === 'legalDisclaimer'){
              privacyAndDesclaimer.push(ele)
          }else {
            consentField.push(ele);
          }
        }
      }*/

      let campaignSections = {
        campaign: action.campaign,
        campaignDetails: campaignDetail,
        fields:profileAndConsentField,
        consentField : campaignDetail && campaignDetail.consents ? campaignDetail.consents : [],
        privacyAndDesclaimer: privacyAndDesclaimer,
        genderValues: genderValues,
        provinceValues: provinceValues
      };

      return { ...state, ...campaignSections };
    }
    case FIELD_SORT_ASYNC_IN_PROGRESS: {
      let asyncInProgress = { asyncInProgress: action.isLoading };
      return { ...state, ...asyncInProgress };
    }
    case UDPATE_PROFILE_FIELDS: {
      let fields = action.fields;
      return { ...state, ...fields };
    }
    case UPDATE_GENDER_VALUES: {
      let genderValues = action.genderValues;
      return { ...state, ...genderValues };
    }
    case UPDATE_PROVINCE_VALUES: {
      let provinceValues = action.provinceValues;
      return { ...state, ...provinceValues };
    }
    default:
      return state;
  }
}
