import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TimlineItem from 'components/organisms/TimelineItem/TimelineItem';
import { Accordion, Menu, Icon, Label } from 'semantic-ui-react';
import classNames from 'classnames';
import constant from '../../../constants';
import 'semantic-ui-css/components/accordion.min.css';
import menuHonorificPrefix from '../../../images/menu-honorificPrefix.png';
import menuUser from '../../../images/menu-user.png';
import menuGender from '../../../images/menu-gender.png';
import menuDate from '../../../images/menu-date.png';
import menuEmail from '../../../images/menu-mail.png';
import menuMobileNumber from '../../../images/menu-mobileNumber.png';
import menuHouseNameOrNumber from '../../../images/menu-houseNameOrNumber.png';
import menuAddressLine1 from '../../../images/menu-addressLine1.png';
import menuCityName from '../../../images/menu-cityName.png';
import menuPostalCode from '../../../images/menu-postalCode.png';
import menuStateOrProvince from '../../../images/menu-stateOrProvince.png';
import menuAddressCountry from '../../../images/menu-stateOrProvince.png';
import menuHeading from '../../../images/menu-heading.png';
import menuParagraph from '../../../images/menu-heading.png';
import menuEntryCode from '../../../images/menu-entryCode.png';
import menuUpload from '../../../images/menu-upload.png';
import menuSubmit from '../../../images/menu-submit.png';
import menuArchive from '../../../images/menu-archive.png';
import menuNumber from '../../../images/menu-number.png';
import legalAgeConsent from '../../../images/menu-user-check.png';
import legalDisclaimer from '../../../images/menu-alert-circle.png';
import termsAndConditions from '../../../images/menu-clipboard.png';
import additionalSubscription from '../../../images/menu-plus-circle.png';
import additionalSubscription1 from '../../../images/menu-plus-circle.png';
import privacyNotice from '../../../images/menu-shield.png';
import contentInterestAreas from '../../../images/menu-list.png';
import brandSMSConsent from '../../../images/menu-message-circle.png';
import unileverSMSConsent from '../../../images/menu-message-circle.png';
import brandEmailConsent from '../../../images/menu-inbox.png';
import unileverEmailConsent from '../../../images/menu-inbox.png';
import unileverOnlineOnlyConsent from '../../../images/online-advertising.png';
import mhmd from 'images/mhmd.png'
import ReactTooltip from 'react-tooltip';
import { epsilonMarket, isUnbundledConsentAndMarketCheck, isWhatsAppConsentEnableForMarket } from 'selectors';
const consentTypes = constant.consentTypes;

function isProfileFieldPresent(fields, key) {
  return fields.some(elem => elem.dataType === key);
}
function isConsentPresent(consents, consentType) {
  return consents.some(consent => consent.optInType === consentType);
}
function getMultiConsentValueBasedOnKey(multiChannelArr, keyValue) {
  return multiChannelArr.find(e => e.value === keyValue);
}

function isConsentPresentForField(profile, consents, dataType, consentType) {
  if (
    isProfileFieldPresent(profile, dataType) &&
    !isConsentPresent(consents, consentType)
  ) {
    return false;
  }
  return true;
}

function MenuAccordion({
  menuItems,
  clickHandler,
  closeHandler,
  editCampaign,
  className,
  statsMode,
  showStats,
  startingIndex,
  isMasterDataOther,
  profile,
  consents,
  campaign,
  campaignConfig,
  isReport
}) {
  const [state, setState] = useState({
    activeIndex: startingIndex != null ? startingIndex : -1
  });
  const handleClick = (e, titleProps) => {
    e.stopPropagation();
    const { index } = titleProps;
    const { activeIndex } = state;
    const newIndex = activeIndex === index ? -1 : index;
    setState({ activeIndex: newIndex });
  };
  let menuIcon = {
    honorificPrefix: menuHonorificPrefix,
    honorificSuffix: menuHonorificPrefix,
    firstName: menuUser,
    middleName: menuUser,
    lastName: menuUser,
    gender: menuGender,
    dateOfBirth: menuDate,
    email: menuEmail,
    mobileNumber: menuMobileNumber,
    houseNameOrNumber: menuHouseNameOrNumber,
    addressLine1: menuAddressLine1,
    addressLine2: menuAddressLine1,
    cityName: menuCityName,
    postalCode: menuPostalCode,
    stateOrProvince: menuStateOrProvince,
    addressCountry: menuAddressCountry,
    heading: menuHeading,
    paragraph: menuParagraph,
    entryCode: menuNumber,
    questionAnswerPair: menuEntryCode,
    samplePrizeSelection: menuArchive,
    fileUpload: menuUpload,
    UGCTextarea: menuEntryCode,
    legalAgeConsent,
    privacyNotice,
    brandEmailConsent,
    brandSMSConsent,
    unileverEmailConsent,
    unileverSMSConsent,
    unileverEmailOnlyConsent: unileverEmailConsent,
    unileverSMSOnlyConsent: unileverSMSConsent,
    unileverOnlineOnlyConsent,
    contentInterestAreas,
    additionalSubscription,
    additionalSubscription1,
    termsAndConditions,
    legalDisclaimer,
    submit: menuSubmit,
    combinedUnileverAndBrandEmailConsent: unileverEmailConsent,
    unileverWhatsAppConsent : unileverSMSConsent,
    brandWhatsAppConsent : unileverSMSConsent,
    myHealthMyDataConsent: mhmd,
    unileverWhatsAppOnlyConsent: unileverSMSConsent,
    multiChannelUnileverConsent: unileverSMSConsent
  };
  return (
    <div className={classNames('menuaccordion', className)}>
      {!statsMode ? (
        <div className="menuaccordion__heading">
          <div className="menuaccordion__heading--title">Form fields</div>{' '}
          <div className="menuaccordion__heading--icon">
            <Icon name="remove circle" onClick={closeHandler} size="large" />
          </div>
        </div>
      ) : null}
      <Accordion vertical="true" fluid>
        {menuItems.map((menuItem, i) => {
          return menuItem.listItems.length ? (
            <Menu.Item
              key={'menuItem-' + menuItem.listId}
              className="menuaccordion__items"
            >
              <Accordion.Title
                active={state.activeIndex === i}
                content={menuItem.listLabel}
                index={i}
                onClick={handleClick}
                className="menuaccordion__items--title"
                icon={state.activeIndex === i ? 'minus' : 'plus'}
                key={'title-' + menuItem.listId + '-' + i}
              />
              <MenuAccordionItem
                data={menuItems[i] && menuItems[i].listItems}
                activeIndex={state.activeIndex === i}
                className={
                  'menuaccordion__items--item' +
                  ' list-' +
                  menuItems[i].listId +
                  '-' +
                  i +
                  ' list-of-' +
                  menuItems.length
                }
                editCampaign={editCampaign}
                menuIcon={menuIcon}
                index={menuItems[i].listId}
                clickHandler={clickHandler}
                key={'list-' + menuItem.listId + '-' + i}
                showStats={showStats}
                isMasterDataOther={isMasterDataOther}
                profile={profile}
                consents={consents}
                campaign={campaign}
                campaignConfig={campaignConfig}
                isReport={isReport}
              />
            </Menu.Item>
          ) : null;
        })}
      </Accordion>
    </div>
  );
}
function MenuAccordionItem({
  data,
  activeIndex,
  className,
  menuIcon,
  editCampaign,
  index,
  clickHandler,
  showStats,
  isMasterDataOther,
  profile,
  consents,
  campaign,
  campaignConfig,
  isReport
}) {
  const isMobileFieldPresent =
    profile && isProfileFieldPresent(profile, 'mobileNumber');
  const isEmailFieldPresent =
    profile && isProfileFieldPresent(profile, 'email');
  const countrySelected = campaign && campaign.country && campaign.country.code;
  const isWhatsAppEnable =
    campaignConfig &&
    campaignConfig.UNILEVER_AND_BRAND_WHATSAPP_CONSENT &&
    isWhatsAppConsentEnableForMarket(campaignConfig, countrySelected) 
    &&  (campaign && campaign.campaignType != 'Incentive' ||
      (campaign && campaign.campaignType === 'Incentive' &&
        campaignConfig.WHATSAPP_CONSENT &&
        campaignConfig.WHATSAPP_CONSENT.INCENTIVE_WHATSAPP_ENABLE));

  const disbaleMultiChannelForWa = !isWhatsAppEnable && !isEmailFieldPresent;
  const isMultiChannelPresent =
    consents &&
    isConsentPresent(consents, consentTypes.multiChannelUnileverConsent);
  const multiChannelData =
    consents &&
    consents.find(
      e => e.optInType === consentTypes.multiChannelUnileverConsent
    );
  const emailMultiConsent =
    multiChannelData &&
    getMultiConsentValueBasedOnKey(
      (multiChannelData && multiChannelData['multi_consents_value']) || [],
      'Email'
    );
  /*const isEmailConsentPresent = isConsentPresentForField(
    profile,
    consents,
    'email',
    consentTypes.unileverEmailConsent
  );*/
  const disableConsentsforMultiChannel = [
    consentTypes.unileverSMSConsent,
    consentTypes.unileverWhatsAppConsent,
  //   consentTypes.unileverEmailConsent
  ];
  if (
    emailMultiConsent &&
    emailMultiConsent.isChecked
    // || isEmailConsentPresent
  ) {
    disableConsentsforMultiChannel.push(consentTypes.unileverEmailConsent);
  }

  const isEpsilon = epsilonMarket(countrySelected)
  const isUnbundledMarket = isUnbundledConsentAndMarketCheck(
    campaignConfig,
    countrySelected
  );

  const isMultiChannelEnable =  campaignConfig && campaignConfig.MULTI_CHANNEL_CONSENT_ENABLE 

  const releaseItems = (
    <div className="menuaccordion__items--wrapper" key={'item-wrap' + index}>
      {data.map(item => {
        //Check for heading and paragraph is added to maintain backward compatibility with earlier implementation
        if(item.optInType === consentTypes.multiChannelUnileverConsent  && !campaignConfig.MULTI_CHANNEL_CONSENT_ENABLE) return null
        return item.type === 'field' &&
          (item.dataType === 'heading' ||
            item.dataType === 'paragraph') ? null : (
          <>
            <button
              onClick={() => clickHandler(item)}
              className="menuaccordion__item"
              key={'item-' + item.dataType + '-' + activeIndex}
              disabled={
                showStats
                  ? false
                  : (item &&
                      item.optInType ===
                        consentTypes.multiChannelUnileverConsent &&
                      (!isMobileFieldPresent || disbaleMultiChannelForWa)) ||
                    (disableConsentsforMultiChannel.includes(
                      item && item.optInType
                    ) &&
                      isMultiChannelPresent)
              }
            >
              <div className="d-flex">
                <div className="menu-item-img-container">
                  <img
                    src={
                      menuIcon[item.dataType] ||
                      menuIcon[item.optInType] ||
                      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII='
                    }
                    alt={item.label}
                    className="menu-item-img"
                  />
                </div>
                <span
                  className={classNames('menu-item-text', {
                    'menu-item-text-keep-case':
                      item.optInType === consentTypes.brandSMSConsent ||
                      item.optInType === consentTypes.unileverSMSConsent ||
                      item.optInType === consentTypes.unileverSMSOnlyConsent ||
                      item.optInType ===
                        consentTypes.combinedUnileverAndBrandEmailConsent ||
                      item.optInType === consentTypes.unileverWhatsAppConsent ||
                      item.optInType === consentTypes.brandWhatsAppConsent ||
                      item.optInType === consentTypes.unileverWhatsAppOnlyConsent
                  })}
                >
                  {item.label}{' '}
                  {(disableConsentsforMultiChannel.includes(
                    item && item.optInType
                  ) || (item.optInType === consentTypes.unileverEmailConsent && !isEpsilon && !isUnbundledMarket)) && !isReport  && isMultiChannelEnable? (
                    <>
                      <ReactTooltip
                        id={item.optInType}
                        place="top"
                        type="info"
                        multiline={true}
                        className="cw-tooltip custom-cw-toolip"
                      />
                      <span
                        className="ml-10 cw-icon cw-icon--help"
                        data-for={item.optInType}
                        data-tip={disableConsentsforMultiChannel.includes(
                          item && item.optInType
                        )  && isMultiChannelPresent ? `Use this field to show separate consent statement for each channel, if you are a GDPR country. Please remove combined multi-channel consent to use individual channel consents`:  `Use this field to show separate consent statement for each channel, if you are a brand in a GDPR country.`}
                      >
                        <i className="fas fa-question-circle"></i>
                      </span>
                    </>
                  ) : null}
                  {item.optInType === consentTypes.multiChannelUnileverConsent && isMultiChannelEnable && !isReport? (
                    <>
                      <ReactTooltip
                        id={item.optInType}
                        place="left"
                        type="info"
                        multiline={true}
                        className="cw-tooltip custom-cw-toolip"
                      />
                      <span
                        className="ml-10 cw-icon cw-icon--help"
                        data-for={item.optInType}
                        data-tip={`Use this field to show a single consent statement with all channels listed. You can use this only if you are a non-GDPR country and the usage has been approved by your local DPO. GDPR countries cannot use this field and instead have to use separate consent statements per channel.`}
                      >
                        <i className="fas fa-question-circle"></i>
                      </span>
                    </>
                  ) : null}
                </span>
                <span className="menu-item-tag"></span>
              </div>
              <div className="text-nowrap">
                {showStats ? (
                  <span className="menu-item-text-right">
                    {item.count || '0'} &#40;{item.percentage || '0'}&#37;&#41;
                  </span>
                ) : null}
              </div>
            </button>
          </>
        );
      })}
    </div>
  );

  const disbledMessage = (
    <p className="disabled">
      These fields are not available for selection during edit
    </p>
  );

  const disbledIncentiveFieldMessage = (
    <p className="disabled">
      The incentive fields are specific to Promotigo vendor driven incentive
      sign up forms and are not available for others.
    </p>
  );
  return (
    <Accordion.Content
      active={activeIndex}
      content={
        editCampaign && (index === '1' || index === '3' || index === '4')
          ? disbledMessage
          : isMasterDataOther && index === '4'
          ? disbledIncentiveFieldMessage
          : releaseItems
      }
      className={className}
    />
  );
}
MenuAccordion.propTypes = {
  menuItems: PropTypes.array.isRequired
};
export default MenuAccordion;
